// look for the Website app ( directive ) 
// initialize angular if found
if($('[ng-app="WebsiteApp"]').length > 0 || $('[data-ng-app="WebsiteApp"]').length > 0){
	
	var ng_app = angular.module('WebsiteApp', [
		'app.controllers', 'app.page_controllers','app.directives', 
		'app.services', 'app.filters', 'mx.directives', 'mx.filters', 'mx.components' , 'mx.services'
	])
	.config(['$httpProvider', function($httpProvider) {
			$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
			$httpProvider.defaults.headers.common["X-Requested-By-Angular"] = 'Angular';
	}])
	.run(function(){});
	
	
	/** ############ @
			Controllers
			############ **/
	
	angular.module('app.page_controllers', []);
	angular.module('app.controllers', []);
	angular.module('app.components', []);
	angular.module('app.directives', [])
			// homepage swiper filter 
		.directive( 'swiperWithFilter', ['$timeout', function( $timeout ){
			return {
				scope : {},
				template : function(){},
				link : function($scope , $element, $attrs ){
					
					var get_color = function( swiper ){
						var activeSlide = swiper.slides[swiper.activeIndex];
						var wrapper = swiper.wrapper;
				    var $color = $(activeSlide).attr("data-bg");
				    //$($(wrapper).find( '.bg')).removeClass('active');
				    //$( $(activeSlide).find( '.bg') ).addClass('active');
				    $($element).css('background-color', $color);
					}// get color function
					
					$scope.init = function(){
						var options = typeof( $attrs.options ) !== 'undefined' ? $attrs.options : {};
						if( options ){
							try{ options = JSON.parse( options );
							}catch( $e ){ console.log('swiperWithFilter JSON Parse Options Error',  $e ) }
						}
						var _options = {
							nextButton: '.swiper-button-next',
							prevButton: '.swiper-button-prev',
							onInit: function(swiper){ get_color(swiper); }
						}
						options = $.extend(_options, options);
						$scope.swiper = new Swiper ($attrs.swiperWithFilter , options )
					  $scope.swiper.on('onClick', function(){ get_color($scope.swiper) });   
						$scope.swiper.on('onSlideNextStart', function(){ get_color($scope.swiper);  });
						$scope.swiper.on('onSlidePrevStart', function(){ get_color($scope.swiper); });

					}
					$scope.updateSwiper = function(){
						$timeout( function(){
							$scope.swiper.update();
							$scope.swiper.slideTo( 0 )
						})
					}
				},
				controller : function( $scope  ){
					//console.log( $scope )
					$timeout( function(){
						$scope.init();
					})
					var $ctrl = $scope.$ctrl  = this;
					$ctrl.current =  'all' ;
					$ctrl.filter = function( $type ){
						$ctrl.current = $type;
						$scope.updateSwiper();
					}		
				}
			}
		}])
			// agnostic swiper directive
			// @TODO : Move to core 
		.directive( 'mxSwiper', ['$timeout', function( $timeout ){
			return {
				scope : {},
				template : function(){},
				link : function( $scope  , $element, $attrs ){
					var options = typeof( $attrs.options ) !== 'undefined' ? $attrs.options : {};
					if( options ){
						try{ options = JSON.parse( options );
						}catch( $e ){ console.log('mxSwiper JSON Parse Options Error',  $e ) }
					}
					var _options = {
						nextButton: '.swiper-button-next',
						prevButton: '.swiper-button-prev',
					}
					options = $.extend(_options, options);
					$scope.swiper = new Swiper ($element , options )
				}
		}}])
		
		// directive for the brew archive filters 
		.directive( 'beerFinder', ['$timeout' , function( $timeout ){
			
			return {
				scope : { data : '=data' },
				template : function(){},
				link : function($scope, $element, $attrs){
					$timeout( function(){
						console.log( $scope );
					})
				},
				controller : function($scope, $element, $attrs ){
					$ctrl = $scope.$ctrl = this;
					$ctrl.availability = null;
					$ctrl.tag = null;
					
					$ctrl.scroll = function( slug ){
			      var target = $('#' + slug);
		        $('html, body').animate({
		          scrollTop: target.offset().top - 100
		        }, 1500);
		        return false;
					};
					
					$ctrl.filter = function(){
						console.log( $ctrl.tag, $ctrl.availability)
						_.each( $ctrl.brews, function( $brew ){
							var active = true 
							if( $ctrl.availability !== null ){
								if( !$brew[$ctrl.availability]) active = false 
							}
							if( $ctrl.tag !== null ) {
								if( $brew.tags.indexOf( $ctrl.tag ) == -1) active = false;
							}
							$brew.active = active;
						})
					}
				}
			}
			
		}])
		;
	angular.module('app.services', []);
	angular.module('app.filters', [])
		// filte for getting keg deposit & totals
		.filter( 'kegTotal' , function(){
			return function ( $input , $is_deposit ){
				var $is_deposit = $is_deposit || false;
				var $total = 0;
				_.each( $input , function( $k , $kk ){
					if( typeof( $k.sixth) !== 'undefined' &&  $k.sixth ){
						if( $is_deposit ) $total += parseFloat($k.sixth_deposit) * $k.sixth_qty;
						else $total += parseFloat($k.sixth) * $k.sixth_qty;
					}
					if( typeof( $k.half) !== 'undefined'  && $k.half){
						if( $is_deposit ) $total += parseFloat($k.half_deposit) * $k.half_qty;
						else $total += parseFloat($k.half) * $k.half_qty;
					}
				} );
				return $total;
			}
		})
		
		
	;



	
}